import { internal } from "@constants/regex";
import useNavigateWithTransition from "@hooks/useNavigateWithTransition";
import { LinkProps } from "@types";
import { Link as GatsbyLink } from "gatsby";
import React from "react";

export default function Link({
  children,
  to,
  activeClassName,
  partiallyActive,
  ...other
}: LinkProps) {
  const isInternal = internal.test(to);
  const { clickHandler } = useNavigateWithTransition({ to });
  if (isInternal) {
    return (
      <GatsbyLink
        onClick={clickHandler}
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a href={to} {...other} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
}

Link.defaultProps = {
  activeClassName: null,
  partiallyActive: null,
  id: "",
};
