import getForBreakpoint from "@utils/getForBreakpoint";
import runForBreakpoint from "@utils/runForBreakpoint";
import { gsap } from "gsap";

const menuEl = ".nav-menu";
const menuWrapperEl = ".nav-menu__wrapper";
const menuHeaderWrapperEl = ".nav-menu__header-wrapper";
const menuContentEl = ".nav-menu__content";
const mainLinkEl = ".nav-menu__list-item";
const socialLinkEl = ".nav-menu__social-link";
const infoEl = ".nav-menu__info";
const baseParams: gsap.TweenVars = { duration: 0.25, ease: "Power1.easeInOut" };
const openTimeline = gsap.timeline({});
const closeTimeline = gsap.timeline({});

export default {
  open: (width: number | null) => {
    const innerElements = getForBreakpoint({
      width,
      base: [mainLinkEl, infoEl],
      md: [mainLinkEl],
    });
    openTimeline.to(menuEl, { display: "block", duration: 0 });
    openTimeline.to([menuContentEl, menuWrapperEl, menuHeaderWrapperEl], {
      opacity: 1,
      filter: "none",
      transformOrigin: "top right",
      duration: 0,
    });
    openTimeline.fromTo(
      menuWrapperEl,
      { scaleX: "0", scaleY: "0" },
      { scaleY: "100%", scaleX: "100%", ...baseParams }
    );
    openTimeline.fromTo(
      [innerElements],
      { opacity: 0, rotateZ: 3, y: 5, transformOrigin: "bottom left" },
      { opacity: 1, stagger: 0.1, rotateZ: 0, y: 0, ...baseParams }
    );
    openTimeline.fromTo(
      socialLinkEl,
      { opacity: 0, x: -5, transformOrigin: "bottom left" },
      { opacity: 1, stagger: 0.1, x: 0, ...baseParams },
      "<45%"
    );
  },
  close: (width: number | null) => {
    runForBreakpoint({
      width,
      base: () => {
        closeTimeline.to(menuEl, {
          display: "none",
          duration: 0,
          onComplete: () => {
            openTimeline.clear();
            return null;
          },
        });
      },
      md: () => {
        closeTimeline.to([menuWrapperEl], {
          transformOrigin: "center center",
          duration: 0,
        });
        closeTimeline.to([menuWrapperEl], {
          ...baseParams,
          duration: 0.55,
          filter: "blur(20px)",
        });
        closeTimeline.to(
          [menuWrapperEl],
          {
            scaleX: "110%",
            scaleY: "110%",
            opacity: 0,
            ...baseParams,
            duration: 0.55,
          },
          "<0%"
        );
        closeTimeline.to(menuEl, {
          display: "none",
          duration: 0,
          onComplete: () => {
            openTimeline.clear();
            return null;
          },
        });
      },
    });
  },
};
