import useNavigateWithTransition from "@hooks/useNavigateWithTransition";
import { LinkProps } from "@types";
import { Link as GatsbyLink } from "gatsby";
import React, { forwardRef } from "react";

const LinkWithRef = forwardRef<any, LinkProps>(
  ({ children, to, activeClassName, partiallyActive, ...other }, ref) => {
    const internal = /^\/(?!\/)/.test(to);
    const { clickHandler } = useNavigateWithTransition({ to });
    if (internal) {
      return (
        <GatsbyLink
          ref={ref}
          onClick={clickHandler}
          to={to}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          {...other}
        >
          {children}
        </GatsbyLink>
      );
    }
    return (
      <a ref={ref} href={to} {...other} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }
);

export default LinkWithRef;
