import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: "https://e155a99431f242bb9f00176027c0f6c8@o4505566203281408.ingest.sentry.io/4505566230937600",
  integrations: [new Sentry.Replay()],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.NODE_ENV === "production",
});
