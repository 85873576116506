import LinkLadder from "@components/ui/general/LinkLadder";
import { LinkProps, TextColor } from "@types";
import classNames from "classnames";
import React from "react";

interface Props extends LinkProps {
  disabled?: boolean;
  children: string;
  color: TextColor;
}

export default function MainLink({ disabled, ...props }: Props) {
  return (
    <LinkLadder
      className={classNames("nav-menu__main-link", {
        [`nav-menu__main-link_disabled`]: disabled,
      })}
      {...props}
    />
  );
}

MainLink.defaultProps = {
  disabled: false,
};
