import "./index.scss";

import Text from "@components/ui/typography/Text";
import classNames from "classnames";
import React, { memo } from "react";

type Props = {
  dark: boolean;
  time: string;
  meridiem: string;
};

function CurrentTime({ dark, time, meridiem }: Props) {
  return (
    <Text
      as="span"
      size="s"
      className={classNames("current-time", {
        [`current-time_dark`]: dark,
      })}
    >
      {time} <span className="current-time__meridiem">{meridiem}</span>
    </Text>
  );
}

export default memo(CurrentTime);
