import gsap from "gsap";

const navbarEl = ".navbar";
const infoEl = ".navbar-info";

export default {
  hide: () => {
    gsap.to(navbarEl, {
      yPercent: -105,
      duration: 0.5,
      ease: "Power4.easeInOut",
      overwrite: true,
    });
    gsap.to(infoEl, {
      yPercent: -100,
      duration: 0.5,
      ease: "Power4.easeInOut",
      overwrite: true,
    });
  },
  show: () => {
    gsap.to(navbarEl, {
      yPercent: 0,
      duration: 0.5,
      ease: "Power4.easeInOut",
      overwrite: true,
    });

    gsap.to(infoEl, {
      yPercent: 0,
      duration: 0.5,
      ease: "Power4.easeInOut",
      overwrite: true,
    });
  },
  reset: () => {
    gsap.set(navbarEl, {
      clearProps: "all",
    });
    gsap.set(infoEl, {
      clearProps: "all",
    });
  },
};
