import Footer from "@components/sections/general/Footer";
import BreakpointPlaceholder from "@components/ui/general/BreakpointPlaceholder";
import MouseFollower from "@components/ui/general/MouseFollower";
import Navbar from "@components/ui/general/Navbar";
import { CaseStudyContext } from "@context/case-study.context";
import { LandingPageContext } from "@context/landing-page.context";
import MouseFollowerProvider from "@context/mouse-follower.context";
import { NotFoundContext } from "@context/not-found.context";
import { PageLoaderContext } from "@context/page-loader.context";
import useSmoothScroll from "@hooks/useSmoothScroll";
import { WindowLocation } from "@reach/router";
import { storyblokInit } from "gatsby-source-storyblok";
import ScrollSmoother from "gsap/ScrollSmoother";
import React, { CSSProperties, ReactNode, useContext, useEffect } from "react";
import { Transition, TransitionGroup } from "react-transition-group";

type Props = {
  children: ReactNode;
  location: WindowLocation<unknown>;
};

storyblokInit({
  accessToken: process.env.GATSBY_STORYBLOK_ACCESS_TOKEN,
  bridge: true,
  apiOptions: {
    resolveNestedRelations: true,
  },
});

const transitionStyles: { [x: string]: CSSProperties } = {
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export default function Layout({ children, location }: Props) {
  useSmoothScroll({
    contentEl: "#smooth-content",
    wrapperEl: "#smooth-wrapper",
    pathname: location.pathname,
  });

  const isCaseStudy =
    location.pathname.includes("showcase/") && location.pathname.length > 10;

  const { caseStudy, setCaseStudy } = useContext(CaseStudyContext);

  const { landingPage } = useContext(LandingPageContext);

  const { isNotFoundPage } = useContext(NotFoundContext);

  const { shortsScrollPos, nextPage } = useContext(PageLoaderContext);

  useEffect(() => {
    if (!isCaseStudy) {
      setCaseStudy(null);
    }
  }, [isCaseStudy]);

  return (
    <MouseFollowerProvider>
      <div id="wrapper">
        <BreakpointPlaceholder text="Seems like you are not using mobile device! Open our website from mobile phone to get the best experience." />
        <MouseFollower />
        <Navbar
          key={isCaseStudy ? "case-study" : location.pathname}
          isAlwaysDark={
            location.pathname === "/" ||
            (location.pathname.startsWith("/showcase") && !caseStudy) ||
            location.pathname.startsWith("/contact") ||
            location.pathname.startsWith("/feed") ||
            location.pathname.startsWith("/privacy-policy") ||
            location.pathname.startsWith("/expertise") ||
            location.pathname.startsWith("/about") ||
            !["/shorts"].some((path) => location.pathname.includes(path)) ||
            caseStudy?.withDarkNavbar ||
            landingPage?.withDarkNavbar ||
            isNotFoundPage
          }
          shouldHide={!isCaseStudy}
        />
        <div id="smooth-wrapper">
          <TransitionGroup>
            <Transition
              key={location.pathname}
              timeout={isCaseStudy ? 300 : 0}
              onExiting={() => {
                ScrollSmoother.get().kill();
                ScrollSmoother.create({
                  content: "#smooth-content",
                  wrapper: "#smooth-wrapper",
                });
                if (nextPage.pageloaderType === "shorts-inner") {
                  ScrollSmoother.get().scrollTo(shortsScrollPos);
                } else {
                  ScrollSmoother.get().scrollTo(0);
                }
              }}
            >
              {(state) => (
                <div id="smooth-content" style={{ ...transitionStyles[state] }}>
                  <main>
                    <div>{children}</div>
                  </main>
                  {(!isCaseStudy && !location?.pathname?.match(/\/shorts/gm)) ||
                  isNotFoundPage ? (
                    <Footer key={location.pathname} />
                  ) : null}
                </div>
              )}
            </Transition>
          </TransitionGroup>
        </div>
      </div>
    </MouseFollowerProvider>
  );
}
