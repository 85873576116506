import BaseIcon from "@components/ui/icons/BaseIcon";
import { IconVariant } from "@types";
import React from "react";

interface Props {
  variant: IconVariant;
}

export default function RectanglesIcon({ variant }: Props) {
  return (
    <BaseIcon
      icon={
        variant === "dark" ? (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="14.5" y="14.5" width="3.3" height="3.3" fill="black" />
            <rect x="14.5" y="22.2002" width="3.3" height="3.3" fill="black" />
            <rect x="22.1992" y="14.5" width="3.3" height="3.3" fill="black" />
            <rect
              x="22.1992"
              y="22.2002"
              width="3.3"
              height="3.3"
              fill="black"
            />
          </svg>
        ) : (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="14.5" y="14.5" width="3.3" height="3.3" fill="white" />
            <rect x="14.5" y="22.2002" width="3.3" height="3.3" fill="white" />
            <rect x="22.1992" y="14.5" width="3.3" height="3.3" fill="white" />
            <rect
              x="22.1992"
              y="22.2002"
              width="3.3"
              height="3.3"
              fill="white"
            />
          </svg>
        )
      }
    />
  );
}
