import { graphql, useStaticQuery } from "gatsby";

export default function useNavigationMenu() {
  const data: Queries.NavigationMenuQuery = useStaticQuery(graphql`
    query NavigationMenu {
      storyblokEntry(slug: { eq: "navigation-menu" }) {
        content
      }
    }
  `);
  return JSON.parse(data.storyblokEntry?.content || "");
}
