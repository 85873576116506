import useCurrentTime from "@hooks/useCurrentTime";
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";

interface Props {
  children: ReactNode;
}

export type PageLoaderType = "default" | "feed-to-shorts" | "shorts-inner";

export type NextPage = {
  pathname: string;
  pageloaderType: PageLoaderType;
};

interface Context {
  isReady: boolean;
  setIsReady: Dispatch<SetStateAction<boolean>>;
  shouldRunAnimations: boolean;
  setShouldRunAnimations: Dispatch<SetStateAction<boolean>>;
  nextPage: NextPage;
  setNextPage: Dispatch<SetStateAction<NextPage>>;
  haveAssetsLoaded: boolean;
  setHaveAssetsLoaded: Dispatch<SetStateAction<boolean>>;
  time: string;
  meridiem: string;
  shortsScrollPos: number;
  setShortsScrollPos: Dispatch<SetStateAction<number>>;
}

export const PageLoaderContext = createContext<Context>({
  isReady: false,
  shouldRunAnimations: false,
  setShouldRunAnimations: () => {},
  setIsReady: () => {},
  nextPage: {
    pathname: "/",
    pageloaderType: "default",
  },
  setNextPage: () => {},
  haveAssetsLoaded: false,
  setHaveAssetsLoaded: () => {},
  time: "",
  meridiem: "",
  shortsScrollPos: 0,
  setShortsScrollPos: () => {},
});

export default function PageLoaderProvider({ children }: Props) {
  // todo: find a good name
  const [isReady, setIsReady] = useState<boolean>(false);
  const [shouldRunAnimations, setShouldRunAnimations] =
    useState<boolean>(false);
  const [pathname, setPathname] = useState("");
  const [haveAssetsLoaded, setHaveAssetsLoaded] = useState<boolean>(false);
  const [nextPage, setNextPage] = useState<NextPage>({
    pathname: "",
    pageloaderType: "default",
  });
  const [time, meridiem] = useCurrentTime();
  const [shortsScrollPos, setShortsScrollPos] = useState(0);
  const value = useMemo(
    () => ({
      isReady,
      setIsReady,
      shouldRunAnimations,
      setShouldRunAnimations,
      pathname,
      setPathname,
      haveAssetsLoaded,
      setHaveAssetsLoaded,
      nextPage,
      setNextPage,
      time,
      meridiem,
      shortsScrollPos,
      setShortsScrollPos,
    }),
    [
      isReady,
      setIsReady,
      shouldRunAnimations,
      setShouldRunAnimations,
      pathname,
      setPathname,
      haveAssetsLoaded,
      setHaveAssetsLoaded,
      nextPage,
      setNextPage,
      time,
      meridiem,
      shortsScrollPos,
      setShortsScrollPos,
    ]
  );
  return (
    <PageLoaderContext.Provider value={value}>
      {children}
    </PageLoaderContext.Provider>
  );
}
