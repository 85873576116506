export const sleep = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export default async function waitForDocumentReadiness() {
  // await sleep(500);
  return new Promise((resolve) => {
    if (
      document.readyState === "complete" ||
      document.readyState === "interactive"
    ) {
      resolve(null);
    } else {
      document.addEventListener("DOMContentLoaded", resolve);
    }
  });
}
