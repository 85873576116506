export const storyblokSections = {
  HOME_HERO: "home_hero_section",
  DRIBBBLE: "dribbble_shots_section",
  INFO_BASIC_NUMBERS: "info_basic_numbers_section",
  INFO_BASIC_LIST: "info_basic_list_section",
  INFO_TABLE: "info_table_section",
  INFO_QUOTE_LIST: "info_quote_list_section",
  INFO_QUOTE_PARAGRAPH: "info_quote_paragraph_section",
  PROJECT_INFO_SUMMARY: "project_info_summary_section",
  PROJECT_INFO_INTRO: "project_info_intro_section",
  COVER: "cover_section",
  TEXT_LEFT: "text_left_section",
  TEXT_CENTER: "text_center_section",
  MEDIA_FULL_WIDTH: "media_full_width_section",
  MEDIA_SPLIT_SCREEN: "media_split_screen_section",
  NEXT_PROJECT: "next_project_section",
  PROJECTS_CATALOG: "projects_catalog_section",
  IMAGE_SEQUENCE: "image_sequence",
  TEAM: "team_section",
  FEATURED_PROJECTS: "featured_projects_section",
  LOGOS: "logos_section",
  REVIEWS: "reviews_section",
  REVIEW_COMPONENT: "review_component",
  RUNNING_LINE: "running_line_section",
  CTA_SECTION: "cta_section",
  INFO_GRID: "info_grid_section",
  CONTACT_FORM: "contact_form_section",
  INTERACTIVE_BOARD: "interactive_board_section",
  INFO_BASIC_PARAGRAPH: "info_basic_paragraph_section",
  INFO_BASIC_CENTERED_LIST: "info_basic_centered_list_section",
  INFO_TABLE_PARAGRAPH: "info_table_paragraph_section",
  INFO_WITH_CARDS: "info_with_cards_section",
  VIDEO_WITH_TEXT: "video_with_text_section",
  CTA_WITH_INPUT: "cta_with_input_section",
  PARALLAX_MEDIA: "parallax_media_section",
  GLOBAL_COMPONENT: "global_component_section",
};

export const storyblokComponents = {
  QUOTE: "quote_component",
  INTERACTIVE_BOARD_MEMBER: "interactive_board_member_component",
  LONGREAD_POST: "feed_longread_post",
  REPORT_POST: "feed_report_post",
  QUICK_POST: "feed_quick_post",
  FEED_CTA: "feed_cta_component",
  FEED_PROJECTS_NAV: "feed_projects_nav_component",
  FEED_IMAGES: "feed_images_component",
  GLOBAL_COMPONENT: "global_component_section",
};

export const resolveRelations = [
  `${storyblokSections.COVER}.project`,
  `${storyblokSections.PROJECT_INFO_SUMMARY}.team_member`,
  `${storyblokSections.PROJECT_INFO_SUMMARY}.project`,
  `${storyblokSections.PROJECT_INFO_SUMMARY}.representative`,
  `${storyblokSections.PROJECT_INFO_SUMMARY}.client`,
  `${storyblokSections.PROJECT_INFO_SUMMARY}.credits`,
  `${storyblokSections.PROJECT_INFO_INTRO}.client`,
  `${storyblokSections.PROJECT_INFO_INTRO}.team_members`,
  `${storyblokSections.PROJECT_INFO_INTRO}.project`,
  `${storyblokSections.NEXT_PROJECT}.project`,
  `${storyblokSections.PROJECTS_CATALOG}.projects`,
  `${storyblokSections.TEAM}.team_members`,
  `${storyblokSections.INFO_TABLE}.mentions`,
  `${storyblokSections.FEATURED_PROJECTS}.projects`,
  `${storyblokSections.REVIEW_COMPONENT}.representative`,
  `${storyblokSections.HOME_HERO}.people`,
  `${storyblokSections.CTA_SECTION}.people`,
  `${storyblokComponents.QUOTE}.team_member`,
  `${storyblokComponents.QUOTE}.representative`,
  `${storyblokComponents.INTERACTIVE_BOARD_MEMBER}.team_member`,
  `${storyblokComponents.LONGREAD_POST}.authors`,
  `${storyblokComponents.LONGREAD_POST}.category`,
  `${storyblokComponents.QUICK_POST}.authors`,
  `${storyblokComponents.QUICK_POST}.category`,
  `${storyblokComponents.REPORT_POST}.authors`,
  `${storyblokComponents.REPORT_POST}.category`,
  `${storyblokComponents.FEED_CTA}.team_members`,
  `${storyblokComponents.FEED_PROJECTS_NAV}.projects`,
  `${storyblokComponents.GLOBAL_COMPONENT}.global_component`,
];
