import transformCategorySlug from "@utils/transformCategorySlug";
import { ISbStoriesParams } from "gatsby-source-storyblok";

import { getStories, getStory } from "./client";

export async function getFeedData(
  per_page = 5,
  page = 1,
  category = "",
  year = ""
) {
  try {
    const options: ISbStoriesParams = {
      per_page,
      page,
      by_slugs: "feed/*",
      excluding_slugs: "feed/,feed/feed_categories/*,feed/events/*",
      sort_by: "content.date:desc",
      cv: Date.now(),
    };

    if (category) {
      options.by_slugs = `feed/${transformCategorySlug.transformToURL(
        category
      )}/*`;
    }

    if (year) {
      /* @ts-ignore */
      options["filter_query[date][gt_date]"] = `${Number(
        Number(year) - 1
      )}-12-31 23:59`;
      /* @ts-ignore */
      options["filter_query[date][lt_date]"] = `${Number(year)}-12-31 23:59`;
    }
    const response = await getStories(options);

    return {
      data: response?.data?.stories,
      total: response?.headers?.total,
    };
  } catch (error: any) {
    throw new Error(error?.message);
  }
}

export async function getArticleData(slug: string) {
  try {
    const response = await getStory(`feed/${slug}`);
    return {
      data: response?.data?.story,
    };
  } catch (error: any) {
    throw new Error(error?.message);
  }
}

export async function getEvents(year: number) {
  try {
    const response = await getStory(`feed/events/${year}`, {
      cv: Date.now(),
    });

    const csvRes = await fetch(response.data.story.content?.csv_file?.filename);
    const csvData = await csvRes.text();

    return {
      data: csvData,
    };
  } catch (error: any) {
    throw new Error(error?.message);
  }
}

export async function getCategories() {
  try {
    const response = await getStories({
      by_slugs: "feed/feed_categories/*",
    });

    return {
      data: response?.data?.stories,
    };
  } catch (error: any) {
    throw new Error(error?.message);
  }
}
