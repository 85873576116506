import gsap from "gsap";

export const outAnimation = () => {
  return new Promise((resolve) => {
    const tl = gsap.timeline({ onComplete: resolve });

    tl.to(".feed-items-wrapper", { y: 100, opacity: 0, duration: 0.5 });
  });
};

export const inAnimation = () => {
  setTimeout(() => {
    const tl = gsap.timeline();

    tl.to(".feed-items-wrapper", { y: 0, opacity: 1, duration: 0.5 });
  }, 0);
};
