import { resolveRelations } from "@constants/storyblok";
import axios from "axios";
import { ISbStoriesParams } from "gatsby-source-storyblok";

const accessToken = process.env.GATSBY_STORYBLOK_ACCESS_TOKEN;
const BASE_URL = `https://api.storyblok.com/v1/cdn/stories`;

const defaultParams = {
  token: accessToken,
  resolve_relations: resolveRelations.join(","),
  version: process.env.BRANCH === "develop" ? "draft" : "published",
};

export const getStory = (slug: string, options?: ISbStoriesParams) => {
  if (options) {
    return axios.get(`${BASE_URL}/${slug}`, {
      params: {
        ...defaultParams,
        ...options,
      },
    });
  }

  return axios.get(`${BASE_URL}/${slug}`, {
    params: defaultParams,
  });
};

export const getStories = (options?: ISbStoriesParams) => {
  if (options) {
    return axios.get(BASE_URL, {
      params: {
        ...defaultParams,
        ...options,
      },
    });
  }

  return axios.get(BASE_URL, {
    params: {
      ...defaultParams,
    },
  });
};
