export default {
  transformToURL: (category: string) => {
    return category.toLocaleLowerCase().replaceAll(" ", "-");
  },
  transformFromURL: (category: string) => {
    return (
      category.split("")[0].toUpperCase() +
      category.replaceAll("-", " ").slice(1)
    );
  },
};
