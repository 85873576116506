import "./index.scss";

import Link from "@components/ui/general/Link";
import { LinkProps, TextColor } from "@types";
import classNames from "classnames";
import React from "react";

interface Props extends LinkProps {
  children: string;
  color?: TextColor;
  isHovered?: boolean;
}

export default function LinkLadder({
  children,
  color,
  id,
  className,
  to,
  isHovered,
  target,
}: Props) {
  return (
    <Link
      to={to}
      id={id}
      className={classNames(className, "link-ladder", {
        [`link-ladder_light`]: color === "white",
        [`link-ladder_dark`]: color === "black",
        [`link-ladder_active`]: isHovered,
      })}
      target={target}
    >
      <span className="link-ladder__word">
        {children.split("").map((letter, i) => {
          if (letter === " ") {
            return (
              <span key={`${letter}${i}`} className="link-ladder__letter">
                &nbsp;
              </span>
            );
          }
          return (
            /* eslint-disable-next-line */
            <span key={`${letter}${i}`} className="link-ladder__letter">
              {letter}
            </span>
          );
        })}
      </span>
    </Link>
  );
}

LinkLadder.defaultProps = {
  color: "black",
  isHovered: false,
};
