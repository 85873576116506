import "./index.scss";

import Text from "@components/ui/typography/Text";
import classNames from "classnames";
import React from "react";

interface Props {
  dark: boolean;
}

export default function Location({ dark }: Props) {
  return (
    <Text
      as="span"
      size="s"
      className={classNames("location", {
        [`location_dark`]: dark,
      })}
    >
      Warsaw, Poland
    </Text>
  );
}
