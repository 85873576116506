import { inAnimation, outAnimation } from "@animations/feed/items";
import { INITIAL_POSTS_COUNT } from "@constants/feed";
import { TOPICS_DEFAULT, YEARS_DEFAULT } from "@constants/filters";
import {
  TOPICS_SEARCH_PARAMS,
  YEARS_SEARCH_PARAMS,
} from "@constants/searchParams";
import { getFeedData } from "@storyblok-api/feedData";
import { SbGatsbyStory } from "gatsby-source-storyblok";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useQueryParamString } from "react-use-query-param-string";

interface Props {
  children: ReactNode;
}

interface Context {
  posts: SbGatsbyStory[];
  setPosts: Dispatch<SetStateAction<SbGatsbyStory[]>>;
  total: number;
  setTotal: Dispatch<SetStateAction<number>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  isWaiting: boolean;
  setIsWaiting: Dispatch<SetStateAction<boolean>>;
  dateInView: string;
  setDateInView: Dispatch<SetStateAction<string>>;
  uploadPosts: () => Promise<any> | void;
  yearsActiveFilter: string;
  setYearsActiveFilter: (value: string) => void;
  topicsActiveFilter: string;
  setTopicsActiveFilter: (value: string) => void;
  isAnimationsDone: boolean;
  setIsAnimationsDone: Dispatch<SetStateAction<boolean>>;
  isSoundOn: boolean;
  setIsSoundOn: Dispatch<SetStateAction<boolean>>;
}

export const FeedContext = createContext<Context>({
  posts: [],
  setPosts: () => {},
  total: 0,
  setTotal: () => {},
  page: 1,
  setPage: () => {},
  isWaiting: false,
  setIsWaiting: () => {},
  dateInView: "",
  setDateInView: () => {},
  uploadPosts: () => {},
  yearsActiveFilter: YEARS_DEFAULT,
  setYearsActiveFilter: () => {},
  topicsActiveFilter: TOPICS_DEFAULT,
  setTopicsActiveFilter: () => {},
  isAnimationsDone: false,
  setIsAnimationsDone: () => {},
  isSoundOn: false,
  setIsSoundOn: () => {},
});

export default function FeedProvider({ children }: Props) {
  const [posts, setPosts] = useState<SbGatsbyStory[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [isWaiting, setIsWaiting] = useState(false);
  const [dateInView, setDateInView] = useState("");
  const [isInited, setIsInited] = useState(false);
  const [isAnimationsDone, setIsAnimationsDone] = useState(false);
  const [isSoundOn, setIsSoundOn] = useState(false);

  const [yearsActiveFilter, setYearsActiveFilter] = useQueryParamString(
    YEARS_SEARCH_PARAMS,
    ""
  );
  const [topicsActiveFilter, setTopicsActiveFilter] = useQueryParamString(
    TOPICS_SEARCH_PARAMS,
    ""
  );

  const uploadPosts = async () => {
    if (page >= total / INITIAL_POSTS_COUNT || isWaiting) return;

    ScrollSmoother.get().paused(true);
    setIsWaiting(true);
    setPage(page + 1);

    const { data } = await getFeedData(
      INITIAL_POSTS_COUNT,
      page + 1,
      !topicsActiveFilter || topicsActiveFilter === TOPICS_DEFAULT
        ? ""
        : topicsActiveFilter,
      !yearsActiveFilter || yearsActiveFilter === YEARS_DEFAULT
        ? ""
        : yearsActiveFilter
    );

    setPosts((prev) => [...prev, ...data]);

    setIsWaiting(false);
    ScrollSmoother.get().paused(false);
  };

  const resetPosts = async () => {
    await outAnimation();
    setPosts([]);
    setPage(1);
    setTotal(0);
  };

  useEffect(() => {
    const resetAndUploadPosts = async () => {
      if (isInited) {
        await resetPosts();

        ScrollSmoother.get().paused(true);
        ScrollSmoother.get().scrollTo(window.innerHeight / 2, true);
        setIsWaiting(true);
        setPage(1);
        const { data, total: totalPosts } = await getFeedData(
          INITIAL_POSTS_COUNT,
          1,
          !topicsActiveFilter || topicsActiveFilter === TOPICS_DEFAULT
            ? ""
            : topicsActiveFilter,
          !yearsActiveFilter || yearsActiveFilter === YEARS_DEFAULT
            ? ""
            : yearsActiveFilter
        );

        setPosts(data);

        setTotal(+totalPosts);

        setIsWaiting(false);
        inAnimation();
        ScrollTrigger.refresh();
        ScrollSmoother.get().paused(false);
      }
    };

    resetAndUploadPosts();
    setIsInited(true);
  }, [yearsActiveFilter, topicsActiveFilter]);

  const value = useMemo(
    () => ({
      posts,
      setPosts,
      total,
      setTotal,
      page,
      setPage,
      isWaiting,
      setIsWaiting,
      dateInView,
      setDateInView,
      uploadPosts,
      yearsActiveFilter: yearsActiveFilter || YEARS_DEFAULT,
      setYearsActiveFilter,
      topicsActiveFilter: topicsActiveFilter || TOPICS_DEFAULT,
      setTopicsActiveFilter,
      isAnimationsDone,
      setIsAnimationsDone,
      isSoundOn,
      setIsSoundOn,
    }),
    [
      posts,
      setPosts,
      total,
      setTotal,
      page,
      setPage,
      isWaiting,
      setIsWaiting,
      dateInView,
      setDateInView,
      yearsActiveFilter,
      setYearsActiveFilter,
      topicsActiveFilter,
      setTopicsActiveFilter,
      isAnimationsDone,
      setIsAnimationsDone,
      isSoundOn,
      setIsSoundOn,
    ]
  );
  return <FeedContext.Provider value={value}>{children}</FeedContext.Provider>;
}
