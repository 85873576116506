import { gsap } from "gsap";
import CustomEase from "gsap/CustomEase";

CustomEase.create("preloader", "0.33, 1, 0.68, 1");

const preloaderEl = ".preloader";
const shadesEl = ".preloader__shade";
const logoEl = ".preloader__logo";
const preloaderFadeBg = ".preloader__fade-bg";
const hideTimeline = gsap.timeline({});
const openTimeline = gsap.timeline({});
const SHADE_DURATION = 0.1;

export default {
  showFadeBg: (onComplete: gsap.CallbackVars["onComplete"]) => {
    gsap.set(preloaderEl, {
      clipPath: "inset(0% 0% 0% 0%)",
    });
    gsap.to(preloaderFadeBg, {
      duration: 0.5,
      opacity: 1,
      onComplete,
    });
  },
  hideFadeBg: (onComplete: gsap.CallbackVars["onComplete"]) => {
    gsap.to(preloaderFadeBg, {
      duration: 0.5,
      opacity: 0,
    });
    gsap.to(preloaderEl, {
      clipPath: "inset(0% 0% 100% 0%)",
      delay: 0.5,
      duration: 0,
      onComplete,
    });
  },
  hide: (onComplete: gsap.CallbackVars["onComplete"]) => {
    hideTimeline.to(shadesEl, {
      scaleX: "0",
      stagger: 0.05,
      duration: SHADE_DURATION,
    });
    hideTimeline.fromTo(logoEl, { opacity: 1 }, { opacity: 0 }, ">-75%");
    hideTimeline.to(preloaderEl, {
      duration: 0,
      clipPath: "inset(0% 0% 100% 0%)",
      onComplete,
    });
  },
  show: (onComplete: gsap.CallbackVars["onComplete"]) => {
    openTimeline.fromTo(
      preloaderEl,
      {
        clipPath: "inset(100% 0% 0% 0%)",
      },
      {
        duration: 0,
        clipPath: "inset(0% 0% 0% 0%)",
      }
    );
    openTimeline.to(shadesEl, {
      scaleX: 1.02,
      stagger: 0.05,
      duration: SHADE_DURATION,
    });
    openTimeline.fromTo(
      logoEl,
      { opacity: 0 },
      {
        opacity: 1,
        onComplete,
      },
      ">-50%"
    );
  },
};
