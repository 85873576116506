import "./index.scss";

import pageloader from "@animations/pageloader";
import { preloaderActiveClassName } from "@constants/preloader";
import { PageLoaderContext } from "@context/page-loader.context";
import { WindowLocation } from "@reach/router";
import waitForDocumentReadiness from "@utils/waitForDocumentReadiness";
import { navigate } from "gatsby";
import Lottie, { LottieRef } from "lottie-react";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import logoLottie from "./logo-lottie.json";

interface Props {
  location: WindowLocation<unknown>;
}

const NUMBER_OF_SHADES = 15;

export default function PageLoader({
  location: { pathname: gatsbyPathname },
}: Props) {
  const lottieRef: LottieRef = useRef(null);
  const wasPlayedOnce = useRef(false);
  const [isDocumentReady, setIsDocumentReady] = useState(false);

  const {
    setIsReady,
    setShouldRunAnimations,
    nextPage,
    haveAssetsLoaded,
    setHaveAssetsLoaded,
  } = useContext(PageLoaderContext);

  const onHideComplete = () => {
    document.body.classList.remove(preloaderActiveClassName);
    setIsReady(true);
    wasPlayedOnce.current = true;
  };

  const onShowComplete = () => {
    document.body.classList.add(preloaderActiveClassName);
    setIsReady(false);
    navigate(nextPage.pathname);
  };

  const hideHandler = () => {
    if (nextPage.pageloaderType === "default") {
      setTimeout(() => {
        pageloader.hide(onHideComplete);
      }, 500);
      setTimeout(() => {
        setShouldRunAnimations(true);
      }, 1250);
    }
    if (nextPage.pageloaderType === "feed-to-shorts") {
      onHideComplete();
      setShouldRunAnimations(true);
    }
    if (nextPage.pageloaderType === "shorts-inner") {
      setTimeout(() => {
        pageloader.hideFadeBg(onHideComplete);
      }, 500);
      setTimeout(() => {
        setShouldRunAnimations(true);
      }, 550);
    }
  };

  const showHandler = () => {
    setShouldRunAnimations(false);
    if (nextPage.pageloaderType === "default") {
      pageloader.show(onShowComplete);
    }
    if (nextPage.pageloaderType === "feed-to-shorts") {
      onShowComplete();
    }
    if (nextPage.pageloaderType === "shorts-inner") {
      pageloader.showFadeBg(onShowComplete);
    }
  };

  useLayoutEffect(() => {
    async function prepare() {
      await Promise.all([waitForDocumentReadiness()]);
      setIsDocumentReady(true);
    }
    prepare();
  }, []);

  useEffect(() => {
    if (!isDocumentReady || !haveAssetsLoaded || wasPlayedOnce.current) return;
    const timeout = setTimeout(() => {
      if (lottieRef.current) {
        lottieRef.current.play();
      }
    }, 500);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeout);
  }, [isDocumentReady, haveAssetsLoaded]);

  useLayoutEffect(() => {
    if (wasPlayedOnce.current) {
      setHaveAssetsLoaded(false);
      showHandler();
    }
  }, [nextPage]);

  useLayoutEffect(() => {
    if (!haveAssetsLoaded) return;
    if (wasPlayedOnce.current && haveAssetsLoaded) {
      hideHandler();
    }
  }, [gatsbyPathname, haveAssetsLoaded]);

  return (
    <div className="preloader">
      <div className="preloader__fade-bg" />
      <div className="preloader__shades">
        {Array.from(Array(NUMBER_OF_SHADES).keys()).map((i) => (
          <div
            key={i}
            className="preloader__shade"
            style={{
              width: `${100 / NUMBER_OF_SHADES}vw`,
              height: "100vh",
              backgroundColor: "black",
            }}
          />
        ))}
      </div>
      <div className="preloader__logo">
        <Lottie
          autoplay={false}
          loop={false}
          animationData={logoLottie}
          lottieRef={lottieRef}
          onComplete={hideHandler}
        />
      </div>
    </div>
  );
}
