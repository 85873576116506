import "./index.scss";

import Link from "@components/ui/general/Link";
import BaseIcon from "@components/ui/icons/BaseIcon";
import Text from "@components/ui/typography/Text";
import { ButtonCircleType } from "@types";
import classNames from "classnames";
import React, { MouseEventHandler, ReactNode } from "react";

interface Props {
  variant?: ButtonCircleType;
  id?: string;
  textVariant: "dark" | "light";
  text: string;
  icon: ReactNode;
  withBorder?: boolean;
  maskIcon?: ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  to?: string;
  label?: string;
}

export default function ButtonCircle({
  variant,
  textVariant,
  id,
  text,
  withBorder,
  className,
  icon,
  maskIcon,
  onClick,
  to,
  label,
}: Props) {
  const color = textVariant === "light" ? "white" : "black";
  const btnClassNames = classNames("button-circle", className, {
    [`button-circle_outline`]: variant === `outline`,
    [`button-circle_outline_white`]: variant === `outline-white`,
    [`button-circle_outline_black`]: variant === `outline-black`,
    [`button-circle_solid`]: variant === `solid`,
  });

  if (!to) {
    return (
      <button
        id={id}
        type="button"
        onClick={onClick}
        className={btnClassNames}
        aria-label={label}
      >
        <Text as="span" size="s" color={color} className="button-circle__text">
          {text}
        </Text>
        <span className="button-circle__icon">
          <BaseIcon
            icon={icon}
            className={classNames("button-circle__base-icon", {
              "button-circle__base-icon_border": withBorder,
            })}
          />
          {maskIcon && (
            <BaseIcon icon={maskIcon} className="button-circle__mask-icon" />
          )}
        </span>
      </button>
    );
  }
  if (to) {
    return (
      <Link to={to} id={id} className={btnClassNames} aria-label={label}>
        <Text as="span" size="s" color={color} className="button-circle__text">
          {text}
        </Text>
        <span className="button-circle__icon">
          <BaseIcon
            icon={icon}
            className={classNames("button-circle__base-icon", {
              "button-circle__base-icon_border": withBorder,
            })}
          />
          {maskIcon && (
            <BaseIcon icon={maskIcon} className="button-circle__mask-icon" />
          )}
        </span>
      </Link>
    );
  }
  return null;
}

ButtonCircle.defaultProps = {
  className: "",
  id: "",
  withBorder: true,
  onClick: null,
  to: null,
  maskIcon: null,
  variant: null,
  label: null,
};
