import "./index.scss";

import classNames from "classnames";
import React, { ReactNode } from "react";

interface Props {
  icon: ReactNode;
  className?: string;
}

export default function BaseIcon({ icon, className }: Props) {
  return <span className={classNames("base-icon", className)}>{icon}</span>;
}

BaseIcon.defaultProps = {
  className: null,
};
