import ScrollSmoother from "gsap/ScrollSmoother";
import { useEffect } from "react";

interface Props {
  contentEl: string;
  wrapperEl: string;
  pathname: string;
}

export default function useSmoothScroll({ contentEl, wrapperEl }: Props) {
  useEffect(() => {
    ScrollSmoother.create({
      content: contentEl,
      wrapper: wrapperEl,
    });
  }, []);
}
