import BREAKPOINTS from "@constants/breakpoints";
import { AnimationParams, BreakpointsEnum } from "@types";

interface Args<T> extends AnimationParams<T> {
  width: number | null;
}

export default function getForBreakpoint<T>({
  width,
  base,
  sm,
  md,
  lg,
  xl,
  _2xl,
  _3xl,
  xxl,
}: Args<T>): T | null {
  if (width) {
    if (xxl && width >= BREAKPOINTS[BreakpointsEnum.xxl].rem) {
      return xxl;
    }
    if (_3xl && width >= BREAKPOINTS[BreakpointsEnum["3xl"]].rem) {
      return _3xl;
    }
    if (_2xl && width >= BREAKPOINTS[BreakpointsEnum["2xl"]].rem) {
      return _2xl;
    }
    if (xl && width >= BREAKPOINTS[BreakpointsEnum.xl].rem) {
      return xl;
    }
    if (lg && width >= BREAKPOINTS[BreakpointsEnum.lg].rem) {
      return lg;
    }
    if (md && width >= BREAKPOINTS[BreakpointsEnum.md].rem) {
      return md;
    }
    if (sm && width >= BREAKPOINTS[BreakpointsEnum.sm].rem) {
      return sm;
    }
  }
  if (base) {
    return base;
  }
  return null;
}
