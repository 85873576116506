import _throttle from "lodash/throttle";
import { useEffect, useState } from "react";

interface Props {
  offset?: number;
}

export default function useScrollPosition({ offset = 50 }: Props) {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = _throttle(() => {
      const { y: posY } = document.body.getBoundingClientRect();
      if (-posY > offset) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }, 50);
    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return { isScrolled };
}
