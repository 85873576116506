import useTextColor from "@hooks/useTextColor";
import {
  BaseProps,
  DynamicElementProps,
  HeadingTag,
  TypographyProps,
} from "@types";
import classNames from "classnames";
import React from "react";

export type HeadingSize = "s" | "m" | "l" | "xl" | "xxl";

export type Tag = HeadingTag | "span" | "p";

type HeadingProps = DynamicElementProps<Tag> &
  TypographyProps<HeadingSize> &
  BaseProps;

export default function Heading({
  as,
  size,
  mobileSize,
  color,
  className,
  children,
}: HeadingProps) {
  const TagName = as;
  const textColorClassName = useTextColor(color);
  return (
    <TagName
      className={classNames(className, textColorClassName, {
        [`heading-${size}`]: !!size,
        [`heading-${mobileSize}_sm`]: !!mobileSize,
      })}
    >
      {children}
    </TagName>
  );
}

Heading.defaultProps = {
  size: null,
  mobileSize: null,
};
