import { gsap } from "gsap";

const followerEl = ".mouse-follower";
const quoteTextEl = ".mouse-follower__quote-text";

export default {
  move: (x: number, y: number) => {
    gsap.to(followerEl, {
      duration: 0.5,
      x,
      y,
      ease: "power2.out",
    });
  },
  rotate: (progress: number) => {
    gsap.to(followerEl, {
      duration: 0.5,
      rotate: (progress - 0.5) * 30,
      ease: "power2.out",
    });
  },
  hide: () => {
    gsap.to(followerEl, {
      duration: 1.25,
      scale: 0,
      ease: "power2.out",
    });
  },
  show: () => {
    gsap.to(followerEl, { duration: 1.25, scale: 1, ease: "power2.out" });
  },
  quote: (width: number, height: number) => {
    gsap.to(followerEl, {
      duration: 0.2,
      width: `${width}vw`,
      height: `${height}vw`,
      borderRadius: "46px",
      ease: "Power1.easeOut",
    });
    gsap.to(quoteTextEl, { duration: 0.35, opacity: 1, delay: 0.55, y: 0 });
  },
  default: () => {
    gsap.to(followerEl, {
      duration: 0.2,
      width: "0.7vw",
      height: "0.7vw",
      ease: "Power1.easeOut",
    });
  },
};
