import { hasWindowObject } from "@constants/client";
import { useEffect, useState } from "react";

import useWindowWidth from "./useWindowWidth";

export default function useIsTouchScreen() {
  const [isTouchScreen, setIsTouchScreen] = useState(false);
  const windowWidth = useWindowWidth({});
  useEffect(() => {
    if (!hasWindowObject) return;
    if (window.matchMedia("(pointer: coarse)").matches) {
      setIsTouchScreen(true);
    } else {
      setIsTouchScreen(false);
    }
  }, [windowWidth]);
  return isTouchScreen;
}
