// should be kept in sync with breakpoints in "@styles/variables.scss"

const BREAKPOINTS = {
  xs: {
    rem: 20,
    px: 320,
    str: "(min-width: 20rem)",
    strReverse: "(max-width: 19.9375rem)",
  },
  sm: {
    rem: 36,
    px: 576,
    str: "(min-width: 36rem)",
    strReverse: "(max-width: 35.9375rem)",
  },
  md: {
    rem: 48,
    px: 768,
    str: "(min-width: 48rem)",
    strReverse: "(max-width: 47.9375rem)",
  },
  lg: {
    rem: 64,
    px: 1024,
    str: "(min-width: 64rem)",
    strReverse: "(max-width: 63.9375rem)",
  },
  xl: {
    rem: 80,
    px: 1280,
    str: "(min-width: 80rem)",
    strReverse: "(max-width: 79.9375rem)",
  },
  _2xl: {
    rem: 90,
    px: 1440,
    str: "(min-width: 90rem)",
    strReverse: "(max-width: 89.9375rem)",
  },
  _3xl: {
    rem: 105,
    px: 1680,
    str: "(min-width: 105rem)",
    strReverse: "(max-width: 104.9375rem)",
  },
  xxl: {
    rem: 120,
    px: 1920,
    str: "(min-width: 120rem)",
    strReverse: "(max-width: 119.9375rem)",
  },
};

export default BREAKPOINTS;
