import { IGatsbyImageData } from "gatsby-plugin-image";
import { CSSProperties, ReactNode } from "react";

export interface BaseProps {
  className?: string;
  children?: ReactNode;
  id?: string;
}

export interface DynamicElementProps<T> {
  as: T;
}

export interface TypographyProps<T> {
  size?: T;
  mobileSize?: T;
  color?: TextColor;
}

export type HeadingTag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export type Color =
  | "white"
  | "white-30"
  | "white-50"
  | "black"
  | "black-30"
  | "black-50"
  | "sunglow"
  | "crayola-blue"
  | "han-purple"
  | "platinum"
  | "green";

export type TextColor = Color;

export type BgColor = Color | "transparent";

export type IconColor = Color;

export type IconVariant = "light" | "dark";

export type Link = {
  to: string;
  text: string;
};

// should be kept in sync with "@constants/breakpoints.ts" and "@styles/variables.scss"
export enum BreakpointsEnum {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  "2xl" = "_2xl",
  "3xl" = "_3xl",
  xxl = "xxl",
}

export type Animation = Partial<
  Omit<Record<BreakpointsEnum, () => any>, "xs">
> & {
  base?: () => any;
};

export type AnimationParams<T> = Partial<
  Omit<Record<BreakpointsEnum, T>, "xs">
> & {
  base?: T;
};

export type TextSize = "xs" | "s" | "m" | "l" | "xl";

export interface LinkProps extends BaseProps {
  to: string;
  activeClassName?: string;
  partiallyActive?: boolean;
  target?: "_self" | "_blank" | "_parent" | "_top";
}

export interface LinkWithArrowProps extends LinkProps {
  color: IconColor;
  textSize?: TextSize;
  mobileTextSize?: TextSize;
  isHovered?: boolean;
  textClassName?: string;
}

export type SectionType = "dark" | "light";

export interface Project {
  title: string;
  year: number;
  heading: string;
  to?: string;
  category: string;
  imageUrl?: string | undefined | null;
  mobileImageUrl?: string | undefined | null;
}

export interface IImage {
  alt?: string | undefined;
  publicURL?: string | null;
  childImageSharp?:
    | {
        readonly gatsbyImageData: IGatsbyImageData;
      }
    | null
    | undefined;
}

export interface ILandingPage {
  title: string;
  description: string;
  sections: any;
  withDarkNavbar?: boolean;
}

export interface IProjectNew {
  id: string;
  title: string;
  description: string;
  categories: string[];
  year: number;
  image?: IImage;
  imageSquared?: IImage;
  bgType: "dark" | "light" | string;
  bgPosition?: CSSProperties["objectPosition"];
  isBgWithFilter?: boolean;
  industry: string;
  durationNumber: number;
  durationText: string;
  recognition?: string[];
  credits?: string[];
  websiteLink?: Link;
  slug: string;
  withDarkNavbar?: boolean;

  // TODO: check if that's ok
  nextProject?: Omit<IProjectNew, "nextProject">;
}

export interface IPerson {
  headshot: IImage;
  name: string;
  shortName?: string;
  position: string;
  photo: IImage;
  link?: Link;
}

export interface IQuoteCard {
  id: string;
  heading: string;
  text: string;
  teamMember: IPerson;
  variant: Color;
  link?: Link;
}

export interface IList {
  title: string;
  items: { text: string; link: any; _uid: string }[];
}

export interface IClutchReview {
  id: string;
  name: string;
  position: string;
  quote: string;
  stars: number;
  to: string;
}

export interface IInfoTableItem {
  id: string;
  name: string;
  subname: string;
  date: string;
  img?: IImage;
  link: Link;
}

export interface IClient {
  companyName: string;
  websiteLink: Link;
}

export interface StoryblokImage {
  alt: string;
  copyright: string;
  fieldtype: string;
  filename: string;
  focus: string;
  id: number;
  is_external_url: boolean;
  name: string;
  title: string;
}

export interface IFeedProjectsSlider {
  title: string;
  image: IImage;
  link: string;
}

export interface IStoryblokStory {
  content: {
    icon: StoryblokImage;
    media: StoryblokImage[];
    date: string;
  };
}
export type ButtonCircleType =
  | "outline"
  | "solid"
  | "outline-white"
  | "outline-black";

export interface IOgImage {
  title: string;
  description: string;
  tag?: string;
  image: StoryblokImage;
  type:
    | "website"
    | "profile"
    | "book"
    | "article"
    | "video.movie"
    | "video.episode"
    | "video.tv_show"
    | "video.other"
    | "music.song"
    | "music.album"
    | "music.playlist"
    | "music.radio_station";
}
