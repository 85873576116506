import "./index.scss";

import caseStudyInteractions from "@animations/header/case-study";
import header from "@animations/header/header";
import menu from "@animations/navigation-menu";
import ButtonCircle from "@components/ui/general/ButtonCircle";
import Container from "@components/ui/general/Container";
import CurrentTime from "@components/ui/general/CurrentTime";
import Link from "@components/ui/general/Link";
import Location from "@components/ui/general/Location";
import Logo from "@components/ui/general/Logo";
import NavigationMenu from "@components/ui/general/NavigationMenu";
import ProgressBar from "@components/ui/general/ProgressBar";
import RectanglesIcon from "@components/ui/icons/RectanglesIcon";
import Text from "@components/ui/typography/Text";
import { CaseStudyContext } from "@context/case-study.context";
import { PageLoaderContext } from "@context/page-loader.context";
import useNavigationMenu from "@hooks/data/useNavigationMenu";
import useScrollDirection from "@hooks/useScrollDirection";
import useScrollPosition from "@hooks/useScrollPosition";
import useScrollProgress from "@hooks/useScrollProgress";
import useWindowWidth from "@hooks/useWindowWidth";
import getForBreakpoint from "@utils/getForBreakpoint";
import classNames from "classnames";
import Flip from "gsap/Flip";
import React, {
  Fragment,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

interface Props {
  isAlwaysDark?: boolean;
  shouldHide?: boolean;
}

export default function Navbar({ isAlwaysDark, shouldHide }: Props) {
  const navigationData = useNavigationMenu();
  const { isScrolled } = useScrollPosition({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [flipLayout, setFlipLayout] = useState<Flip.FlipState | null>(null);
  const flipTargets = useRef(["#flip-info", "#menu-btn"]);
  const width = useWindowWidth({ inRems: true });
  const direction = useScrollDirection();
  const { caseStudy, isTransitioning, nextProject } =
    useContext(CaseStudyContext);
  const { time, meridiem } = useContext(PageLoaderContext);
  const scrollProgress = useScrollProgress();

  useEffect(() => {
    if (width) {
      const res = getForBreakpoint({
        width,
        base: ["#menu-btn"],
        md: ["#flip-info", "#menu-btn"],
      });
      if (res) {
        flipTargets.current = res;
      }
    }
  }, [width]);

  useLayoutEffect(() => {
    if (isMenuOpen) {
      menu.open(width);
    }
    if (!isMenuOpen) {
      menu.close(width);
    }
  }, [isMenuOpen]);

  useLayoutEffect(() => {
    if (!flipLayout) return;
    Flip.from(flipLayout, {
      duration: 0.25,
      ease: "power1.inOut",
      targets: flipTargets.current,
    });
  }, [isMenuOpen]);

  useEffect(() => {
    if (!shouldHide) return;
    if (direction === "up") {
      header.show();
    } else {
      header.hide();
    }
  }, [direction, shouldHide]);

  useEffect(() => {
    if (!caseStudy) return;
    if (direction === "up") {
      caseStudyInteractions.show();
    } else {
      caseStudyInteractions.hide();
    }
  }, [direction, caseStudy]);

  useEffect(() => {
    if (!caseStudy) return;
    // eslint-disable-next-line consistent-return
    return () => {
      caseStudyInteractions.reset();
    };
  }, [width, caseStudy]);

  useEffect(() => {
    if (isTransitioning) {
      header.hide();
    } else {
      header.show();
    }
  }, [isTransitioning]);

  const closeHandler = () => {
    const state = Flip.getState(flipTargets.current);
    setFlipLayout(state);
    setIsMenuOpen(false);
  };

  const openHandler = () => {
    const state = Flip.getState(flipTargets.current);
    setFlipLayout(state);
    setIsMenuOpen(true);
  };

  return (
    <Fragment>
      <NavigationMenu
        isOpen={isMenuOpen}
        onClose={closeHandler}
        time={time}
        meridiem={meridiem}
      />
      <header
        className={classNames("header", {
          [`header_light`]: isAlwaysDark || isScrolled,
        })}
      >
        <div className="header__content">
          <div className="navbar">
            <Container>
              <div className="navbar__container">
                <Link to="/" className="navbar__logo" aria-label="Home">
                  <Logo
                    variant={isAlwaysDark || isScrolled ? "dark" : "light"}
                  />
                </Link>
                <div className="navbar__content">
                  {!isMenuOpen && (
                    <div
                      className="navbar__info"
                      id="flip-info"
                      data-flip-id="navbar-info-flip"
                    >
                      <Location dark={isAlwaysDark || isScrolled} />
                      <Text as="span" size="s">
                        &nbsp;
                      </Text>
                      <CurrentTime
                        dark={isAlwaysDark || isScrolled}
                        time={time}
                        meridiem={meridiem}
                      />
                    </div>
                  )}
                  {!isMenuOpen && (
                    <div id="menu-btn" data-flip-id="menu-btn-flip">
                      <ButtonCircle
                        id="nav_bar_open_button"
                        text={navigationData.open_text}
                        icon={
                          <RectanglesIcon
                            variant={
                              isAlwaysDark || isScrolled ? "dark" : "light"
                            }
                          />
                        }
                        maskIcon={
                          <RectanglesIcon
                            variant={
                              isAlwaysDark || isScrolled ? "light" : "dark"
                            }
                          />
                        }
                        textVariant={
                          isAlwaysDark || isScrolled ? "dark" : "light"
                        }
                        // variant="outline-black"
                        variant={
                          isAlwaysDark || isScrolled
                            ? "outline-black"
                            : "outline-white"
                        }
                        onClick={openHandler}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Container>
          </div>
          {caseStudy && (
            <div className="navbar-info">
              <Container>
                <div className="navbar-info__content">
                  <Text as="span" size="s" color="black">
                    {caseStudy.title}
                  </Text>
                  {nextProject?.slug && (
                    <div>
                      <Link
                        to={nextProject?.slug}
                        className="navbar-info__link"
                      >
                        <Text as="span" size="xs" color="black-50">
                          next project
                        </Text>
                        <Text as="span" size="s" color="black">
                          {nextProject?.title}
                        </Text>
                      </Link>
                    </div>
                  )}
                </div>
              </Container>
            </div>
          )}
        </div>

        {caseStudy && isScrolled && (
          <div className="header__progress">
            <ProgressBar progress={scrollProgress / 100} />
          </div>
        )}
      </header>
    </Fragment>
  );
}

Navbar.defaultProps = {
  isAlwaysDark: false,
  shouldHide: false,
};
