import {
  LongreadPost,
  MediaPost,
  Post,
  PostType,
  PostVariants,
  QuickPost,
  ReportPost,
  StoryblokAuthor,
  StoryblokPost,
} from "@components/sections/general/Feed/types";
import {
  IClient,
  IClutchReview,
  IInfoTableItem,
  ILandingPage,
  IPerson,
  IProjectNew,
  StoryblokImage,
} from "@types";
import transformCategorySlug from "@utils/transformCategorySlug";

export const getLink = (item: any): string => {
  if (item.cached_url && item.cached_url === "home") {
    return "/";
  }
  return item.url || `/${item.cached_url}`;
};

export const getMention = (item: any): IInfoTableItem => {
  return {
    id: item.uuid,
    name: item.content.name,
    subname: item.content.publication_name,
    date: item.content.date.split(" ")[0],
    img: {
      alt: item.content.hover_media.alt,
      publicURL: item.content.hover_media.filename,
    },
    link: {
      text: item.content.name,
      to: getLink(item.content.link),
    },
  };
};

export const getReview = (item: any): IClutchReview => ({
  id: item._uid,
  name: item.representative.content.full_name,
  position: item.representative.content.position,
  quote: item.text,
  stars: +item.rating,
  to: item.link.cached_url,
});

export const getLandingPage = (content: any): ILandingPage => {
  return {
    title: content.SEO.title,
    description: content.SEO.description,
    sections: content.sections,
    withDarkNavbar: content.with_dark_navbar,
  };
};

export const getProject = ({ content, full_slug }: any): IProjectNew => {
  return {
    id: content._uid,
    title: content.title,
    description: content.description,
    year: new Date(content.date.split(" ")[0]).getFullYear(),
    categories: content.services.map((service: any) => service.text) || [{}],
    image: {
      alt: content.cover.alt,
      publicURL: content.cover.filename,
    },
    imageSquared: content.cover_squared
      ? {
          alt: content.cover_squared?.alt,
          publicURL: content.cover_squared?.filename,
        }
      : undefined,
    bgType: "dark",
    durationNumber: content.duration_number,
    durationText: content.duration_text,
    industry: content.industry,
    slug: `/${full_slug}`,
    withDarkNavbar: content.with_dark_navbar,
  };
};

export const getRepresentative = ({ content }: any): IPerson => ({
  name: content.full_name,
  position: content.position,
  headshot: {
    alt: content.image.alt,
    publicURL: content.image.filename,
  },
  // TODO: fix these typings
  photo: {
    alt: "",
    publicURL: "",
  },
});

export const getTeamMember = ({ content }: any): IPerson => {
  return {
    name: content.full_name,
    position: content.position,
    headshot: {
      alt: content.headshot_image.alt || "",
      publicURL:
        content.headshot_image.filename ||
        process.env.GATSBY_PLACEHOLDER_AVATAR,
    },
    // TODO: fix these typings
    photo: {
      alt: content.image.alt || "",
      publicURL:
        content.image.filename || process.env.GATSBY_PLACEHOLDER_AVATAR,
    },
    link: {
      to: content.link.url,
      text: "",
    },
  };
};

export const getClient = ({ content }: any): IClient => {
  return {
    companyName: content.company_name,
    websiteLink: {
      to: content.website_link.url,
      text: content.website_link.url,
    },
  };
};

const getPostType = (field_component: string): PostType | null => {
  if (field_component === "feed_longread_post") {
    return PostType.LONGREAD;
  }

  if (field_component === "feed_report_post") {
    return PostType.REPORT;
  }
  if (field_component === "feed_media_post") {
    return PostType.MEDIA;
  }

  if (field_component === "feed_quick_post") {
    return PostType.QUICK;
  }
  return null;
};

const getAuthors = (authors: StoryblokAuthor[]) => {
  return authors.map((author) => ({
    headshot: {
      publicURL: author.content.headshot_image.filename,
      alt: author.content.headshot_image.alt,
    },
    name: author.content.full_name,
    shortName: author.content.full_name,
    position: author.content.position,
    photo: {
      publicURL: author.content.image.filename,
      alt: author.content.image.alt,
    },
  }));
};

export const getPostImages = (images: StoryblokImage[]) => {
  return images.map((image) => ({
    publicURL: image.filename,
    alt: image.alt,
  }));
};

const getDefaultPostData = ({
  content,
  slug,
  full_slug,
}: StoryblokPost): Post => {
  const common: Post = {
    category: content.category?.content?.name,
    date: content.date.split(" ")[0],
    full_slug,
    slug,
    id: content._uid,
    type: getPostType(content.component),
  };

  if (content?.authors?.length) {
    common.authors = getAuthors(content.authors);
  }

  if (content?.body) {
    common.body = content.body;
  }

  return common;
};

export const getMediaPost = ({
  content,
  slug,
  full_slug,
}: StoryblokPost): MediaPost => {
  return {
    ...getDefaultPostData({ content, slug, full_slug }),
    media: content.media?.length ? getPostImages(content.media) : null,
  };
};

export const getLongreadPost = ({
  content,
  slug,
  full_slug,
}: StoryblokPost): LongreadPost => {
  return {
    ...getDefaultPostData({ content, slug, full_slug }),
    title: content.title,
    subtitle: content.subtitle,
    cover: content.cover?.filename,
  };
};

export const getReportPost = ({
  content,
  slug,
  full_slug,
}: StoryblokPost): ReportPost => {
  return {
    ...getDefaultPostData({ content, slug, full_slug }),
    images: content.images?.length ? getPostImages(content.images) : null,
  };
};

export const getQuickPost = ({
  content,
  slug,
  full_slug,
}: StoryblokPost): QuickPost => {
  return {
    ...getDefaultPostData({ content, slug, full_slug }),
  };
};

export const getPost = ({
  content,
  slug,
  full_slug,
}: StoryblokPost): PostVariants | null => {
  if (getPostType(content.component) === PostType.LONGREAD) {
    return getLongreadPost({ content, slug, full_slug });
  }

  if (getPostType(content.component) === PostType.MEDIA) {
    return getMediaPost({ content, slug, full_slug });
  }

  if (getPostType(content.component) === PostType.QUICK) {
    return getQuickPost({ content, slug, full_slug });
  }

  if (getPostType(content.component) === PostType.REPORT) {
    return getReportPost({ content, slug, full_slug });
  }
  return null;
};

// export const getInfoNumbersProps = ({ blok }: any) => ({

// });

export const getCategoriesName = (categories: string[]) => {
  return categories?.map((category) =>
    transformCategorySlug.transformFromURL(category)
  );
};

export const temp = {};
