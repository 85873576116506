import useTextColor from "@hooks/useTextColor";
import {
  BaseProps,
  DynamicElementProps,
  TextSize,
  TypographyProps
} from "@types";
import classNames from "classnames";
import React from "react";

type TextTag = "p" | "span" | "figcaption";

type TextProps = DynamicElementProps<TextTag> &
  TypographyProps<TextSize> &
  BaseProps;

type ExtendedTextProps = TextProps & {
  customColor?: string;
};

export default function Text({
  as,
  size,
  mobileSize,
  color,
  customColor,
  className,
  children
}: ExtendedTextProps) {
  const TagName = as;
  const textColorClassName = useTextColor(color);
  return (
    <TagName
      className={classNames(className, textColorClassName, {
        [`text-${size}`]: !!size,
        [`text-${mobileSize}_sm`]: !!mobileSize
      })}
      style={{ color: customColor }}
    >
      {children}
    </TagName>
  );
}

Text.defaultProps = {
  customColor: null
};
