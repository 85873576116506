import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";

interface Props {
  children: ReactNode;
}

interface Context {
  sequenceImages: HTMLImageElement[];
  setSequenceImages: Dispatch<SetStateAction<HTMLImageElement[]>>;
}

export const AssetsContext = createContext<Context>({
  sequenceImages: [],
  setSequenceImages: () => {},
});

export default function AssetsProvider({ children }: Props) {
  const [sequenceImages, setSequenceImages] = useState<HTMLImageElement[]>([]);

  const value = useMemo(
    () => ({
      sequenceImages,
      setSequenceImages,
    }),
    [sequenceImages, setSequenceImages]
  );

  return (
    <AssetsContext.Provider value={value}>{children}</AssetsContext.Provider>
  );
}
