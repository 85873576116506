import getCurrentTime from "@utils/getCurrentTime";
import { useLayoutEffect, useState } from "react";

export default function useCurrentTime(): string[] {
  const [date, setDate] = useState<string[]>(["", ""]);
  useLayoutEffect(() => {
    const interval = setInterval(() => {
      const newDate = getCurrentTime();
      setDate(newDate);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return date;
}
