import "intersection-observer";
import "./src/styles/index.scss";

import Layout from "@components/misc/Layout";
import PageLoader from "@components/ui/general/PageLoader";
import PageLoaderPreview from "@components/ui/general/PageLoaderPreview";
import { preloaderActiveClassName } from "@constants/preloader";
import AssetsProvider from "@context/assets.context";
import CaseStudyProvider from "@context/case-study.context";
import FeedProvider from "@context/feed.context";
import LandingPageProvider from "@context/landing-page.context";
import NotFoundProvider from "@context/not-found.context";
import PageLoaderProvider from "@context/page-loader.context";
import StoriesProvider from "@context/stories.context";
import { GatsbyBrowser } from "gatsby";
import gsap from "gsap";
import CustomEase from "gsap/CustomEase";
import Flip from "gsap/Flip";
import MorphSVGPlugin from "gsap/MorphSVGPlugin";
import ScrollSmoother from "gsap/ScrollSmoother";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/SplitText";
import React from "react";

gsap.registerPlugin(
  ScrollSmoother,
  ScrollTrigger,
  SplitText,
  CustomEase,
  Flip,
  MorphSVGPlugin
);

/* eslint-disable */
export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props: { location },
}) => {
  return (
    <AssetsProvider>
      <NotFoundProvider>
        <StoriesProvider>
          <FeedProvider>
            <CaseStudyProvider>
              <LandingPageProvider>
                <PageLoaderProvider>
                  {process.env.GATSBY_DANGEROUS_NO_PAGELOADER === "1" ? (
                    <PageLoaderPreview />
                  ) : (
                    <PageLoader location={location} />
                  )}
                  <Layout location={location}>{element}</Layout>
                </PageLoaderProvider>
              </LandingPageProvider>
            </CaseStudyProvider>
          </FeedProvider>
        </StoriesProvider>
      </NotFoundProvider>
    </AssetsProvider>
  );
};

export const onInitialClientRender = () => {
  window.scrollTo(0, 0);
  if (process.env.GATSBY_DANGEROUS_NO_PAGELOADER !== "1") {
    document.body.classList.add(preloaderActiveClassName);
  }
};

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = () => {
  return false;
};
