import "./index.scss";

import ButtonCircle from "@components/ui/general/ButtonCircle";
import Container from "@components/ui/general/Container";
import Link from "@components/ui/general/Link";
import LinkLadder from "@components/ui/general/LinkLadder";
import LinkWithArrow from "@components/ui/general/LinkWithArrow";
import Logo from "@components/ui/general/Logo";
import ArrowRightIcon from "@components/ui/icons/ArrowRightIcon";
import Heading from "@components/ui/typography/Heading";
import Text from "@components/ui/typography/Text";
import useFooter from "@hooks/data/useFooter";
import getLinkIdFromString from "@utils/getLinkIdFromString";
import { getLink } from "@utils/storyblok/adapters";
import classNames from "classnames";
import React from "react";

export default function Footer() {
  const footerData = useFooter();
  const mainLink = footerData.link[0];
  const emailLink = footerData.email_link[0];
  return (
    <footer id="footer" className="footer">
      <Container className="footer__container">
        <div className="footer__body">
          <div className="footer__main">
            <Link to="/" className="footer__logo" aria-label="Home">
              <Logo variant="dark" />
            </Link>
            <Heading
              className="footer__heading"
              as="h3"
              color="black"
              size="xl"
            >
              {footerData.heading}
            </Heading>
            <Text className="footer__cta-text" as="p" color="black" size="s">
              {footerData.subheading}
            </Text>
            <ButtonCircle
              id={`footer_circle_button_${getLinkIdFromString(mainLink.text)}`}
              className="footer__cta-btn"
              textVariant="dark"
              variant="outline"
              to={getLink(mainLink.link)}
              text={mainLink.text}
              icon={<ArrowRightIcon variant="dark" />}
              maskIcon={<ArrowRightIcon variant="light" />}
            />
            <Text
              className="footer__email-text"
              color="black"
              as="span"
              size="s"
            >
              {footerData.email_text}
            </Text>
            <LinkWithArrow
              id={`footer_arrow_link_${getLinkIdFromString(emailLink.text)}`}
              className="footer__email-link"
              color="black"
              to={getLink(emailLink.link)}
            >
              {emailLink.text}
            </LinkWithArrow>
          </div>
          <div className="footer__info">
            <div className="footer__legal">
              <Heading
                className="footer__block-title"
                size="s"
                as="span"
                color="black"
              >
                {footerData.info_title}
              </Heading>
              <div className="footer__legal-block">
                <Text as="span" color="black" size="s">
                  {footerData.info}
                </Text>
              </div>
            </div>
            <div className="footer__links">
              <div className="footer__social-links">
                <Heading
                  className="footer__block-title"
                  size="s"
                  color="black"
                  as="span"
                >
                  {footerData.social_links_title}
                </Heading>
                {footerData.social_links.map((socialLink: any) => (
                  <LinkWithArrow
                    key={socialLink._uid}
                    id={`footer_social_link_${getLinkIdFromString(
                      socialLink._uid
                    )}`}
                    className="footer__social-link"
                    color="black"
                    textSize="s"
                    mobileTextSize="m"
                    to={getLink(socialLink.link)}
                  >
                    {socialLink.text}
                  </LinkWithArrow>
                ))}
              </div>
              <div className="footer__main-links">
                {footerData.main_links.map((mainLinkItem: any) => (
                  <LinkLadder
                    key={mainLinkItem._uid}
                    id={`footer_main_link_${getLinkIdFromString(
                      mainLinkItem._uid
                    )}`}
                    className={classNames("footer__main-link", "heading-s", {
                      [`footer__main-link_disabled`]: mainLinkItem.disabled,
                    })}
                    color="black"
                    to={getLink(mainLinkItem.link)}
                    target="_top"
                  >
                    {mainLinkItem.text}
                  </LinkLadder>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="footer__footer">
          <Text as="span" size="xs">
            © Unikorns {new Date().getFullYear()}. All rights reserved •{" "}
          </Text>
          <Link
            className="footer__privacy-link"
            to="/privacy-policy"
            id="footer_button_privacy_policy"
          >
            <Text as="span" size="xs">
              Privacy Policy
            </Text>
          </Link>
        </div>
      </Container>
    </footer>
  );
}
