import "./index.scss";

import ButtonCircle from "@components/ui/general/ButtonCircle";
import CurrentTime from "@components/ui/general/CurrentTime";
import Location from "@components/ui/general/Location";
import CrossIcon from "@components/ui/icons/CrossIcon";
import Text from "@components/ui/typography/Text";
import useCaseStudyCount from "@hooks/data/useCaseStudies";
import useNavigationMenu from "@hooks/data/useNavigationMenu";
import getLinkIdFromString from "@utils/getLinkIdFromString";
import { getLink } from "@utils/storyblok/adapters";
import classNames from "classnames";
import React, { Fragment, useEffect, useRef, useState } from "react";

import MainLink from "./MainLink";
import SocialLink from "./SocialLink";

type Props = {
  onClose: () => void;
  isOpen: boolean;
  time: string;
  meridiem: string;
};

export default function NavigationMenu({
  onClose,
  isOpen,
  time,
  meridiem,
}: Props) {
  const navigationData = useNavigationMenu();
  const caseStudyCount = useCaseStudyCount();
  const [isLight] = useState(true);
  const ref = useRef<HTMLDivElement | null>(null);
  const [btnActive, setBtnActive] = useState(false);
  useEffect(() => {
    function scrollHandler() {
      onClose();
    }
    function clickHandler(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    }
    function keyPressHandler(event: any) {
      if (event.key === "Escape") {
        event.stopPropagation();
        onClose();
      }
    }
    document.addEventListener("keydown", keyPressHandler);
    document.addEventListener("scroll", scrollHandler);
    document.addEventListener("mousedown", clickHandler);

    return () => {
      document.removeEventListener("keydown", keyPressHandler);
      document.removeEventListener("scroll", scrollHandler);
      document.removeEventListener("mousedown", clickHandler);
    };
  }, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setBtnActive(true);
    }, 400);
    return () => {
      setBtnActive(false);
      clearTimeout(timeout);
    };
  }, [isOpen]);

  const bottomLeftLink = navigationData.bottom_left_link[0];
  return (
    <Fragment>
      <div
        className={classNames("nav-menu__bg", {
          [`nav-menu__bg_open`]: isOpen,
        })}
      />
      <div
        id="nav-menu"
        ref={ref}
        className={classNames("nav-menu", {
          [`nav-menu_light`]: isLight,
        })}
      >
        <div className="nav-menu__header-wrapper">
          <div className="nav-menu__header">
            {isOpen && (
              <div
                className="nav-menu__info"
                id="flip-info"
                data-flip-id="navbar-info-flip"
              >
                <Location dark={isLight} />
                <Text as="span" size="s">
                  &nbsp;
                </Text>
                <CurrentTime dark={isLight} time={time} meridiem={meridiem} />
              </div>
            )}
            {isOpen && (
              <div
                id="menu-btn"
                data-flip-id="menu-btn-flip"
                className="nav-menu__btn"
              >
                <ButtonCircle
                  id="nav_bar_close_button"
                  textVariant={isLight ? "dark" : "light"}
                  onClick={onClose}
                  icon={<CrossIcon variant="light" />}
                  maskIcon={<CrossIcon variant="dark" />}
                  text={navigationData.close_text}
                  className={classNames("nav-menu__btn-circle", {
                    [`nav-menu__btn-circle_open`]: btnActive,
                  })}
                />
              </div>
            )}
          </div>
        </div>

        <div className="nav-menu__wrapper">
          <div className="nav-menu__content">
            <nav className="nav-menu__nav">
              <ul className="nav-menu__list">
                {navigationData.main_links.map((link: any) => (
                  <li key={link._uid} className="nav-menu__list-item">
                    <MainLink
                      color={isLight ? "black" : "white"}
                      to={getLink(link.link)}
                      id={`nav_button_${getLinkIdFromString(link.text)}`}
                    >
                      {link.text}
                    </MainLink>
                    {link.link.cached_url === "showcase/" && (
                      <span className="link-pill">{caseStudyCount}</span>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
            <div className="nav-menu__footer">
              <div className="nav-menu__email">
                <SocialLink
                  color={isLight ? "black" : "white"}
                  id={`nav_button_${getLinkIdFromString(bottomLeftLink.text)}`}
                  to={getLink(bottomLeftLink.link)}
                >
                  {bottomLeftLink.text}
                </SocialLink>
              </div>
              <div className="flex justify-end w-100">
                <ul className="nav-menu__list">
                  {navigationData.secondary_links.map((link: any) => (
                    <li key={link._uid}>
                      <SocialLink
                        id={`nav_button_${getLinkIdFromString(link.text)}`}
                        color={isLight ? "black" : "white"}
                        to={getLink(link.link)}
                      >
                        {link.text}
                      </SocialLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
