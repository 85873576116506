import "./index.scss";

import ArrowRight from "@components/Icons/ArrowRight";
import Link from "@components/ui/general/Link";
import Text from "@components/ui/typography/Text";
import { LinkWithArrowProps } from "@types";
import classNames from "classnames";
import React from "react";

export default function LinkWithArrow({
  children,
  color,
  className,
  textClassName,
  textSize,
  mobileTextSize,
  isHovered,
  ...props
}: LinkWithArrowProps) {
  return (
    <Link
      className={classNames("link", className, {
        [`text-white`]: color === "white",
        [`text-black`]: color === "black",
        [`link_hovered`]: isHovered,
      })}
      {...props}
    >
      <span className="link__content">
        <Text
          as="span"
          size={textSize || "s"}
          mobileSize={mobileTextSize || "s"}
          className={classNames("link__text", textClassName)}
        >
          {children}
        </Text>
        <span className="link__icon">
          <span className="link__arrow">
            <ArrowRight color={color} />
          </span>
          <span className="link__arrow link__arrow_clone">
            <ArrowRight color={color} />
          </span>
        </span>
      </span>
    </Link>
  );
}
