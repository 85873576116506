import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";

interface Props {
  children: ReactNode;
}

interface Context {
  isNotFoundPage: boolean;
  setIsNotFoundPage: Dispatch<SetStateAction<boolean>>;
}

export const NotFoundContext = createContext<Context>({
  isNotFoundPage: false,
  setIsNotFoundPage: () => {},
});

export default function NotFoundProvider({ children }: Props) {
  const [isNotFoundPage, setIsNotFoundPage] = useState(false);

  const value = useMemo(
    () => ({
      isNotFoundPage,
      setIsNotFoundPage,
    }),
    [isNotFoundPage, setIsNotFoundPage]
  );
  return (
    <NotFoundContext.Provider value={value}>
      {children}
    </NotFoundContext.Provider>
  );
}
