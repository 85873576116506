import _debounce from "lodash/debounce";
import { useEffect,useState } from "react";

import useRootFontSize from "./useRootFontSize";

type WindowWidth = number | null;

interface Props {
  inRems?: boolean;
}

export default function useWindowWidth({ inRems }: Props): WindowWidth {
  const [windowWidth, setWindowWidth] = useState<WindowWidth>(null);
  const rootFontSize = useRootFontSize();
  useEffect(() => {
    const handleResize = _debounce(
      () => {
        if (inRems && rootFontSize) {
          setWindowWidth(window.innerWidth / rootFontSize);
        } else if (!inRems) {
          setWindowWidth(window.innerWidth);
        }
      },
      200,
      { trailing: true }
    );
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [rootFontSize]);

  return windowWidth;
}
