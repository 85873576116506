import React from "react";

interface Props {
  variant: "dark" | "light" | "white";
}

export default function CrossIcon({ variant }: Props) {
  if (variant === "dark") {
    return (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="20" r="20" fill="black" />
        <path d="M15 15L25 25M25 15L15 25" stroke="white" />
      </svg>
    );
  }
  if (variant === "light") {
    return (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="20" r="19.5" stroke="black" strokeOpacity="0.3" />
        <line
          x1="13.3536"
          y1="13.6464"
          x2="26.3536"
          y2="26.6464"
          stroke="black"
        />
        <line
          y1="-0.5"
          x2="18.3848"
          y2="-0.5"
          transform="matrix(-0.707107 0.707107 0.707107 0.707107 27 14)"
          stroke="black"
        />
      </svg>
    );
  }
  if (variant === "white") {
    return (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="20" r="20" fill="white" />
        <line
          x1="13.3536"
          y1="13.6464"
          x2="26.3536"
          y2="26.6464"
          stroke="black"
        />
        <line
          y1="-0.5"
          x2="18.3848"
          y2="-0.5"
          transform="matrix(-0.707107 0.707107 0.707107 0.707107 27 14)"
          stroke="black"
        />
      </svg>
    );
  }
  return null;
}
