import { ILandingPage } from "@types";
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";

interface Props {
  children: ReactNode;
}

interface Context {
  landingPage: ILandingPage | null;
  setLandingPage: Dispatch<SetStateAction<ILandingPage | null>>;
}

export const LandingPageContext = createContext<Context>({
  landingPage: null,
  setLandingPage: () => {},
});

export default function LandingPageProvider({ children }: Props) {
  const [landingPage, setLandingPage] = useState<ILandingPage | null>(null);

  const value = useMemo(
    () => ({
      landingPage,
      setLandingPage,
    }),
    [landingPage, setLandingPage]
  );

  return (
    <LandingPageContext.Provider value={value}>
      {children}
    </LandingPageContext.Provider>
  );
}
