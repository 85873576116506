import "./index.scss";

import mouseFollowerAnimations from "@animations/mouse-follower";
import { hoverableElements } from "@constants/mouse-follower";
import { MouseFollowerContext } from "@context/mouse-follower.context";
import useIsTouchScreen from "@hooks/useIsTouchScreen";
import classNames from "classnames";
import gsap from "gsap";
import _clamp from "lodash/clamp";
import React, { useContext, useEffect, useRef } from "react";

// const quoteXOffset = 150;
// const quoteYOffset = 55;
// const quoteFollowerWidth = 15;
// const quoteFollowerHeight = 3;
const defaultWalls = { x1: 0, x2: 0, y1: 0, y2: 0, active: false };

export default function MouseFollower() {
  const { currentEl } = useContext(MouseFollowerContext);
  const { current: walls } = useRef(defaultWalls);
  const isTouchScreen = useIsTouchScreen();
  const ref = useRef(null);
  useEffect(() => {
    if (isTouchScreen) return;
    if (!ref.current) return;

    gsap.set(ref.current, { xPercent: -50, yPercent: -50, scale: 0 });

    const handler = ({ clientX, clientY }: MouseEvent) => {
      if (walls.active) {
        const x = _clamp(clientX, walls.x1, walls.x2);
        const y = _clamp(clientY, walls.y1, walls.y2);
        mouseFollowerAnimations.move(x, y);
      } else {
        mouseFollowerAnimations.move(clientX, clientY);
      }
    };
    const leaveHandler = () => {
      mouseFollowerAnimations.hide();
    };
    const enterHandler = () => {
      mouseFollowerAnimations.show();
    };
    window.addEventListener("mousemove", handler);
    document.addEventListener("mouseleave", leaveHandler);
    document.addEventListener("mouseenter", enterHandler);
    /* eslint-disable */
    return () => {
      window.removeEventListener("mousemove", handler);
      document.removeEventListener("mouseleave", leaveHandler);
      document.removeEventListener("mouseenter", enterHandler);
    };
    /* eslint-enable */
  }, [isTouchScreen]);

  useEffect(() => {
    if (isTouchScreen) return;
    if (!currentEl) {
      walls.active = false;
      mouseFollowerAnimations.default();
      // return;
    }
    // if (currentEl.id === hoverableElements.QUOTE) {
    //   const mouseMoveHandler = (event: MouseEvent) => {
    //     if (currentEl.ref?.current) {
    //       const windowWidth = window.innerWidth;
    //       const { left, top, width, bottom } =
    //         currentEl.ref.current.getBoundingClientRect();
    //       const x = event.clientX - left;
    //       const progress = +(x / width).toFixed(2);
    //       walls.active = true;
    //       walls.x1 = quoteXOffset;
    //       walls.x2 = windowWidth - quoteXOffset;
    //       walls.y1 = top + quoteYOffset;
    //       walls.y2 = bottom - quoteYOffset;
    //       mouseFollowerAnimations.rotate(progress);
    //       mouseFollowerAnimations.quote(
    //         quoteFollowerWidth,
    //         quoteFollowerHeight
    //       );
    //     }
    //   };
    //   const scrollHandler = () => {
    //     setCurrentEl(null);
    //   };
    //   window.addEventListener("mousemove", mouseMoveHandler);
    //   window.addEventListener("scroll", scrollHandler);
    //   /* eslint-disable */
    //   return () => {
    //     window.removeEventListener("mousemove", mouseMoveHandler);
    //     window.removeEventListener("scroll", scrollHandler);
    //   };
    //   /* eslint-enable */
    // }
  }, [currentEl, isTouchScreen]);
  if (!isTouchScreen) {
    return (
      <span
        className={classNames("mouse-follower", {
          [`mouse-follower_quote`]: currentEl?.id === hoverableElements.QUOTE,
          [`mouse-follower_project`]:
            currentEl?.id === hoverableElements.PROJECT,
          [`mouse-follower_board`]: currentEl?.id === hoverableElements.BOARD,
        })}
        ref={ref}
      />
    );
  }
  return null;
}
