import { internal } from "@constants/regex";
import {
  PageLoaderContext,
  PageLoaderType,
} from "@context/page-loader.context";
import { useLocation } from "@reach/router";
import { useContext } from "react";

interface Props {
  to: string;
  pageloaderType?: PageLoaderType;
}

interface Navigate {
  clickHandler: (event: any) => void;
}

export default function useNavigateWithTransition({
  to,
  pageloaderType = "default",
}: Props): Navigate {
  const { setNextPage } = useContext(PageLoaderContext);
  const { pathname: locationPathname } = useLocation();
  const clickHandler = (event: any) => {
    if (!internal.test(to)) {
      window.open(to);
      return;
    }
    if (event) {
      event.persist();
      event.preventDefault();
    }
    if (locationPathname !== to && locationPathname !== `${to}/`)
      setNextPage({ pathname: to, pageloaderType });
  };
  return { clickHandler };
}
