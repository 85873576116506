import { graphql, useStaticQuery } from "gatsby";

export default function useFooter() {
  const data: Queries.FooterQuery = useStaticQuery(graphql`
    query Footer {
      storyblokEntry(slug: { eq: "footer" }) {
        content
      }
    }
  `);
  return JSON.parse(data.storyblokEntry?.content || "");
}
