import { IImage } from "@types";
// @ts-ignore-next-line
// eslint-disable-next-line import/extensions
import { getFluidGatsbyImage } from "@utils/gatsby-storyblok-image/src/index.js";
import Img from "gatsby-image";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

interface Props {
  image: IImage;
  className?: string;
  wrapperClassName?: string;
  loading?: "eager" | "lazy";
  objectFit?: React.CSSProperties["objectFit"];
  objectPosition?: React.CSSProperties["objectPosition"];
  style?: React.CSSProperties;
  onLoad?: () => void;
  storyblokImageOptions?: any;
  forceNative?: boolean;
  withWrapper?: boolean;
}

function CustomImageDefault({
  image,
  className,
  wrapperClassName,
  loading,
  objectFit,
  objectPosition,
  style,
  onLoad,
  storyblokImageOptions,
  forceNative,
  withWrapper,
  ...rest
}: Props) {
  if (image?.childImageSharp?.gatsbyImageData && !forceNative) {
    return (
      <div className={wrapperClassName}>
        <GatsbyImage
          alt={image.alt || ""}
          image={image.childImageSharp.gatsbyImageData}
          className={className}
          loading={loading}
          objectFit={objectFit}
          objectPosition={objectPosition}
          style={style}
          onLoad={onLoad}
          {...rest}
        />
      </div>
    );
  }

  if (image?.publicURL) {
    let url = image.publicURL;
    const isStoryblokImage = image.publicURL.includes("a.storyblok.com");
    const isSvg = image.publicURL.endsWith(".svg");
    if (isStoryblokImage && !isSvg && !forceNative) {
      url = `${url}/m/`;
      const storyblokImageProps = getFluidGatsbyImage(image.publicURL, {
        ...storyblokImageOptions,
      });

      // Console error: For fix console error about react hydrating, you need set loading params to "eager"
      if (withWrapper) {
        return (
          <div className={wrapperClassName} {...rest}>
            <Img
              fluid={storyblokImageProps}
              loading={loading}
              onLoad={onLoad}
            />
          </div>
        );
      }
      return (
        <Img
          className={wrapperClassName}
          fluid={storyblokImageProps}
          loading={loading}
          onLoad={onLoad}
          {...rest}
        />
      );
    }
    return (
      <div className={wrapperClassName}>
        <img
          alt={image.alt || ""}
          className={className}
          loading={loading}
          src={url}
          style={style}
          onLoad={onLoad}
          {...rest}
        />
      </div>
    );
  }

  return null;
}

export default React.memo(CustomImageDefault);

CustomImageDefault.defaultProps = {
  className: null,
  wrapperClassName: null,
  loading: null,
  objectFit: "cover",
  objectPosition: "50% 50%",
  style: null,
  onLoad: null,
  storyblokImageOptions: {
    quality: 95,
    sizeMultipliers: [0.15, 0.5, 1, 1.5, 2, 3],
  },
  forceNative: false,
  withWrapper: false,
};
