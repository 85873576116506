import { useEffect, useState } from "react";

export default function useRootFontSize() {
  const [rootFontSize, setRootFontSize] = useState<number | null>(null);
  useEffect(() => {
    let fontSize = window
      .getComputedStyle(document.body)
      .getPropertyValue("font-size");
    [fontSize] = fontSize.split("px");
    setRootFontSize(+fontSize);
  }, []);
  return rootFontSize;
}
