import "./index.scss";

import gsap from "gsap";
import React, { useEffect } from "react";

interface Props {
  progress: number;
}

export default function ProgressBar({ progress }: Props) {
  useEffect(() => {
    gsap.to(".progress-bar__fill", { scaleX: progress });
  }, [progress]);
  return (
    <div className="progress-bar">
      <div className="progress-bar__fill" />
    </div>
  );
}
