import BaseIcon from "@components/ui/icons/BaseIcon";
import { IconVariant } from "@types";
import React from "react";

interface Props {
  variant: IconVariant;
}

export default function ArrowRightIcon({ variant }: Props) {
  return (
    <BaseIcon
      icon={
        variant === "dark" ? (
          <svg
            width="40"
            height="41"
            viewBox="0 0 40 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="14"
              y1="20.5651"
              x2="25"
              y2="20.5651"
              stroke="black"
              strokeWidth="1.21739"
            />
            <path
              d="M20.3203 16L24.8855 20.5652L20.6247 24.8261"
              stroke="black"
              strokeWidth="1.21739"
            />
          </svg>
        ) : (
          <svg
            width="40"
            height="41"
            viewBox="0 0 40 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="14"
              y1="20.5651"
              x2="25"
              y2="20.5651"
              stroke="white"
              strokeWidth="1.21739"
            />
            <path
              d="M20.3203 16L24.8855 20.5652L20.6247 24.8261"
              stroke="white"
              strokeWidth="1.21739"
            />
          </svg>
        )
      }
    />
  );
}
