import { IImage, IPerson, StoryblokImage } from "@types";
import { StoryblokRichtext } from "storyblok-rich-text-react-renderer";

export interface StoryblokPostCategory {
  content: {
    name: string;
    uid: string;
  };
}

export interface StoryblokAuthor {
  content: {
    full_name: string;
    headshot_image: StoryblokImage;
    image: StoryblokImage;
    link: any;
    position: string;
  };
}
export interface StoryblokPostContent {
  _uid: string;
  title: string;
  subtitle: string;
  date: string;
  component: string;
  media: StoryblokImage[];
  cover: {
    filename: string;
  };
  authors: StoryblokAuthor[];
  category: StoryblokPostCategory;
  images: StoryblokImage[];
  body: StoryblokRichtext;
}

export interface StoryblokPost {
  content: StoryblokPostContent;
  full_slug: string;
  slug: string;
}

export enum PostType {
  MEDIA = "MEDIA",
  REPORT = "REPORT",
  LONGREAD = "LONGREAD",
  QUICK = "QUICK",
}

export interface Post {
  id: string;
  slug: string;
  full_slug: string;
  type: PostType | null;

  date: string;
  authors?: IPerson[] | null;
  category: string;
  body?: StoryblokRichtext;
}

export interface LongreadPost extends Post {
  title: string;
  subtitle: string;
  cover: string;
}

export interface ReportPost extends Omit<Post, "body"> {
  images: IImage[] | null;
}

export interface QuickPost extends Post {}

export interface MediaPost extends Omit<Post, "authors"> {
  media: IImage[] | null;
}

export type PostVariants = QuickPost | LongreadPost | MediaPost | ReportPost;
