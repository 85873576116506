import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";

interface Props {
  children: ReactNode;
}

interface Context {
  isSoundOn: boolean;
  setIsSoundOn: Dispatch<SetStateAction<boolean>>;
}

export const StoriesContext = createContext<Context>({
  isSoundOn: false,
  setIsSoundOn: () => {},
});

export default function StoriesProvider({ children }: Props) {
  const [isSoundOn, setIsSoundOn] = useState(false);

  const value = useMemo(
    () => ({
      isSoundOn,
      setIsSoundOn,
    }),
    [isSoundOn, setIsSoundOn]
  );

  return (
    <StoriesContext.Provider value={value}>{children}</StoriesContext.Provider>
  );
}
