import { IProjectNew } from "@types";
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";

interface Props {
  children: ReactNode;
}

interface Context {
  caseStudy: IProjectNew | null;
  setCaseStudy: Dispatch<SetStateAction<IProjectNew | null>>;
  isTransitioning: boolean;
  setIsTransitioning: Dispatch<SetStateAction<boolean>>;
  nextProject: IProjectNew | null;
  setNextProject: Dispatch<SetStateAction<IProjectNew | null>>;
}

export const CaseStudyContext = createContext<Context>({
  caseStudy: null,
  setCaseStudy: () => {},
  isTransitioning: false,
  setIsTransitioning: () => {},
  nextProject: null,
  setNextProject: () => {},
});

export default function CaseStudyProvider({ children }: Props) {
  const [caseStudy, setCaseStudy] = useState<IProjectNew | null>(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [nextProject, setNextProject] = useState<IProjectNew | null>(null);
  const value = useMemo(
    () => ({
      caseStudy,
      setCaseStudy,
      isTransitioning,
      setIsTransitioning,
      nextProject,
      setNextProject,
    }),
    [
      caseStudy,
      setCaseStudy,
      isTransitioning,
      setIsTransitioning,
      nextProject,
      setNextProject,
    ]
  );
  return (
    <CaseStudyContext.Provider value={value}>
      {children}
    </CaseStudyContext.Provider>
  );
}
