import gsap from "gsap";

const headerEl = ".header";

export default {
  hide: () => {
    gsap.to(headerEl, {
      yPercent: -105,
      duration: 0.5,
      ease: "Power4.easeIn",
      overwrite: true,
    });
  },
  show: () => {
    gsap.to(headerEl, {
      yPercent: 0,
      duration: 0.5,
      ease: "Power4.easeIn",
      overwrite: true,
    });
  },
};
