exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-expertise-index-tsx": () => import("./../../../src/pages/expertise/index.tsx" /* webpackChunkName: "component---src-pages-expertise-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-shorts-index-tsx": () => import("./../../../src/pages/shorts/index.tsx" /* webpackChunkName: "component---src-pages-shorts-index-tsx" */),
  "component---src-pages-showcase-index-tsx": () => import("./../../../src/pages/showcase/index.tsx" /* webpackChunkName: "component---src-pages-showcase-index-tsx" */),
  "component---src-templates-case-study-index-tsx": () => import("./../../../src/templates/case-study/index.tsx" /* webpackChunkName: "component---src-templates-case-study-index-tsx" */),
  "component---src-templates-feed-article-tsx": () => import("./../../../src/templates/feed/article.tsx" /* webpackChunkName: "component---src-templates-feed-article-tsx" */),
  "component---src-templates-feed-index-tsx": () => import("./../../../src/templates/feed/index.tsx" /* webpackChunkName: "component---src-templates-feed-index-tsx" */),
  "component---src-templates-home-index-tsx": () => import("./../../../src/templates/home/index.tsx" /* webpackChunkName: "component---src-templates-home-index-tsx" */),
  "component---src-templates-stories-index-tsx": () => import("./../../../src/templates/stories/index.tsx" /* webpackChunkName: "component---src-templates-stories-index-tsx" */)
}

