export default function getCurrentTime() {
  const date = new Date();
  return date
    .toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "Europe/Warsaw",
    })
    .split(" ");
}
