import React, {
  createContext,
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
  useMemo,
  useState,
} from "react";

interface Props {
  children: ReactNode;
}

interface CurrentElement {
  id: string;
  ref: RefObject<HTMLElement | HTMLDivElement>;
}

interface Context {
  currentEl: CurrentElement | null;
  setCurrentEl: Dispatch<SetStateAction<CurrentElement | null>>;
}

export const MouseFollowerContext = createContext<Context>({
  currentEl: null,
  setCurrentEl: () => {},
});

export default function MouseFollowerProvider({ children }: Props) {
  const [currentEl, setCurrentEl] = useState<CurrentElement | null>(null);
  const value = useMemo(
    () => ({ currentEl, setCurrentEl }),
    [currentEl, setCurrentEl]
  );
  return (
    <MouseFollowerContext.Provider value={value}>
      {children}
    </MouseFollowerContext.Provider>
  );
}
