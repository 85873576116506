import { TextColor } from "@types";
import classNames from "classnames";

export default function useTextColor(color?: TextColor): string {
  return classNames({
    "text-white": color === "white",
    "text-white-50": color === "white-50",
    "text-white-30": color === "white-30",
    "text-sunglow": color === "sunglow",
    "text-crayola-blue": color === "crayola-blue",
    "text-han-purple": color === "han-purple",
    "text-black": color === "black",
    "text-black-50": color === "black-50",
    "text-black-30": color === "black-30",
  });
}
