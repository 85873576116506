import { IconColor } from "@types";
import React from "react";

type Props = {
  color: IconColor;
};

export default function ArrowRight({ color }: Props) {
  return (
    <svg
      className="arrow-right"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="0.00195313"
        y1="8.85474"
        x2="15.0966"
        y2="8.85474"
        stroke={color}
        strokeWidth="1.64208"
      />
      <path
        d="M8.52832 2.69751L14.6861 8.8553L8.93884 14.6026"
        stroke={color}
        strokeWidth="1.64208"
      />
    </svg>
  );
}
