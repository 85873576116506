import { useEffect, useRef, useState } from "react";

import useWindowWidth from "./useWindowWidth";

export default function useScrollProgress(): number {
  const [progress, setProgress] = useState<number>(0);
  const windowWidth = useWindowWidth({});
  const blocking = useRef(false);

  useEffect(() => {
    const updateScrollPosition = () => {
      // This will calculate how many pixels the page is vertically
      const winScroll = document.documentElement.scrollTop;
      // This is responsible for subtracticing the total height of the page - where the users page is scrolled to
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;

      // This will calculate the final total of the percentage of how much the user has scrolled.
      const scrollProgress = Math.round((winScroll / height) * 100);

      setProgress(scrollProgress);
      blocking.current = false;
    };

    const onScroll = () => {
      if (!blocking.current) {
        blocking.current = true;
        window.requestAnimationFrame(updateScrollPosition);
      }
    };

    updateScrollPosition();
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [windowWidth]);

  return progress;
}
