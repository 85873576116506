import { graphql, useStaticQuery } from "gatsby";

export default function useCaseStudyCount() {
  const data: Queries.CaseStudiesQuery = useStaticQuery(graphql`
    query CaseStudies {
      allStoryblokEntry(
        filter: {
          full_slug: { regex: "/^showcase//" }
          is_startpage: { eq: false }
        }
      ) {
        edges {
          node {
            id
            slug
          }
        }
      }
    }
  `);
  return data.allStoryblokEntry.edges.length;
}
